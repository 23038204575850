import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Typography } from "@material-ui/core";
import Layout from "../components/Layout/Layout";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootNotFoundPage: {
        position: 'relative',
      },
      messageBox: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }
    }
  ),
);

const NotFoundPage: React.FC = () => {
  const classes = useStyles();
  return (
    <Layout className={classes.rootNotFoundPage} title="Page not found">
      <div className={classes.messageBox}>
        <Typography variant="h4">Page Not Found</Typography>
      </div>
    </Layout>
  );
};

export default NotFoundPage;